// we need to re-export types & interfaces under an alias instead of directly
// re-exporting them to prevent a typescript error.
// https://github.com/facebook/create-react-app/issues/6054
import {
  RadioQuestionComponentProps as RQCP,
  RadioAnswer as RA
} from "./Questions/RadioQuestion";

export { Survey as default } from "./Survey";
export { Survey } from "./Survey";

export * from "./types";
export * from "./Redux/actions";

export { surveyReducer } from "./Redux/reducers";

export type RadioAnswer = RA;
export type RadioQuestionComponentProps = RQCP;
