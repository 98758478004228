import React from "react";
import { SvgIcon, SvgIconProps } from "./SvgIcon";

export const AgreeChevron: React.FC<SvgIconProps> = ({
  width = 16,
  height = 16,
  viewBox = "0 0 16 16",
  ...props
}) => {
  return (
    <SvgIcon width={width} height={height} viewBox={viewBox} {...props}>
      <path
        fill="#43b02a"
        fillRule="nonzero"
        d="M 12.167969 7.832031 L 5.542969 14.457031 C 5.429688 14.570312 5.296875 14.625 5.144531 14.625 C 4.988281 14.625 4.855469 14.570312 4.742188 14.457031 L 3.257812 12.972656 C 3.144531 12.859375 3.089844 12.726562 3.089844 12.570312 C 3.089844 12.417969 3.144531 12.28125 3.257812 12.167969 L 8 7.429688 L 3.257812 2.6875 C 3.144531 2.574219 3.089844 2.441406 3.089844 2.285156 C 3.089844 2.132812 3.144531 1.996094 3.257812 1.882812 L 4.742188 0.402344 C 4.855469 0.289062 4.988281 0.230469 5.144531 0.230469 C 5.296875 0.230469 5.429688 0.289062 5.542969 0.402344 L 12.167969 7.027344 C 12.28125 7.140625 12.339844 7.273438 12.339844 7.429688 C 12.339844 7.582031 12.28125 7.71875 12.167969 7.832031 Z M 12.167969 7.832031 "
      />
    </SvgIcon>
  );
};

export default AgreeChevron;
