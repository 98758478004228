import React from "react";
import { Button, Typography, Grid, Hidden, Theme } from "@material-ui/core";
import { useHistory } from "react-router";
import { strings } from "src/Constants/Strings";
import { makeStyles, createStyles } from "@material-ui/styles";
import { Stopwatch } from "src/Icons/Stopwatch";
import { ArrowRight } from "src/Icons/ArrowRight";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontSize: 54,
      fontWeight: 300,
      fontFamily: "KPMG",
      color: theme.palette.primary.contrastText,
      marginTop: 67
    },
    introBold: {
      fontSize: 16,
      lineHeight: 1.4,
      color: theme.palette.primary.contrastText,
      marginTop: 30,
      fontWeight: "bold"
    },
    intro: {
      fontSize: 16,
      lineHeight: 1.4,
      color: theme.palette.primary.contrastText,
      marginTop: 30,
      fontWeight: 300
    },
    button: {
      fontWeight: 200,
      textTransform: "none",
      width: 350,
      height: 80,
      marginTop: 50,
      fontSize: 28,
      [theme.breakpoints.down("xs")]: {
        width: 270,
        fontSize: 20
      }
    },
    buttonIcon: {
      marginLeft: 15
    },
    timeTaken: {
      marginTop: 25,
      display: "flex",
      flexDirection: "row",
      marginBottom: 50
    },
    timeTakenText: {
      marginLeft: 13,
      lineHeight: "36px",
      color: theme.palette.primary.contrastText,
      opacity: 0.7,
      fontSize: 14,
      [theme.breakpoints.down("xs")]: {
        lineHeight: 1
      }
    }
  })
);

export const HomePage: React.FC = () => {
  const history = useHistory();
  const onNavigate = React.useCallback(() => history.push("/survey"), [
    history
  ]);
  const styles = useStyles();

  return (
    <Grid container justify="flex-start">
      <Grid item style={{ width: "15%" }}></Grid>
      <Grid item xs={8}>
        <Grid container>
          <Grid item xs={12} md={8}>
            <Typography variant="h2" className={styles.title}>
              {strings.home.title}
            </Typography>
            <Typography className={styles.introBold}>
              {strings.home.introBold}
            </Typography>
            <Typography className={styles.intro}>
              {strings.home.intro}
            </Typography>
            <Typography className={styles.intro}>
              {strings.home.intro2}
            </Typography>
            <Button
              id="start-survey"
              variant="contained"
              color="secondary"
              onClick={onNavigate}
              className={styles.button}
              endIcon={<ArrowRight className={styles.buttonIcon} />}
            >
              {strings.home.getScore}
            </Button>
            <div className={styles.timeTaken}>
              <Stopwatch />
              <Typography className={styles.timeTakenText}>
                {strings.home.timeTaken}
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
