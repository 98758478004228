import createMuiTheme, {
  ThemeOptions
} from "@material-ui/core/styles/createMuiTheme";
import { paletteOptions } from "./PaletteOptions";
import {
  buttonOverrides,
  appBarOverrides,
  typographyOverrides,
  outlinedInputOverrides,
  paperDefaultProps,
  checkboxDefaultProps
} from "./ComponentOptions";

const myTheme: ThemeOptions = {
  palette: paletteOptions,
  overrides: {
    MuiButton: buttonOverrides,
    MuiAppBar: appBarOverrides,
    MuiTypography: typographyOverrides,
    MuiOutlinedInput: outlinedInputOverrides
  },
  props: {
    MuiPaper: paperDefaultProps,
    MuiCheckbox: checkboxDefaultProps
  }
};

export const theme = createMuiTheme(myTheme);
