import { combineReducers } from "redux";
import { surveyReducer } from "src/Components/Survey";
import { submissionReducer } from "./Areas/Submission/reducers";

export const rootReducer = combineReducers({
  survey: surveyReducer,
  submission: submissionReducer
});

export type AppState = ReturnType<typeof rootReducer>;
