import React from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Grid,
  Theme,
  Hidden
} from "@material-ui/core";
import KpmgLogo from "src/Icons/KpmgLogo";
import { makeStyles, createStyles, useTheme } from "@material-ui/styles";
import { strings } from "src/Constants/Strings";
import Footer from "src/Components/Footer/Footer.tsx";
import { PageContainer, ContentContainer } from "./indexStyle";
import { useLocation } from "react-router";
import { CookieBanner } from "src/Components/CookieBanner/CookieBanner";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    alignCenter: {
      textAlign: "center"
    },
    main: {
      marginBottom: 150
    },
    smTitle: {
      [theme.breakpoints.down("xs")]: {
        fontSize: 30
      }
    }
  })
);

const getBackgroundColorForRoute = (route: string, theme: Theme): string => {
  switch (route) {
    case "/":
      return theme.palette.primary.main;
    default:
      return theme.palette.background.paper;
  }
};

export const Layout: React.FC = ({ children }) => {
  const styles = useStyles();

  const route = useLocation();
  const theme = useTheme<Theme>();

  const bgColor = React.useMemo(
    () => getBackgroundColorForRoute(route.pathname, theme),
    [route, theme]
  );

  return (
    <PageContainer backgroundColor={bgColor}>
      <ContentContainer>
        <AppBar>
          <Toolbar>
            <Grid container justify="center">
              <Grid item sm={2} xs={2} className={styles.alignCenter}>
                <KpmgLogo />
              </Grid>
              <Grid item sm={6} xs={10} className={styles.alignCenter}>
                <Typography
                  color="primary"
                  variant="h1"
                  className={styles.smTitle}
                >
                  {strings.layout.title}
                </Typography>
              </Grid>
              <Hidden xsDown>
                <Grid item sm={2} />
              </Hidden>
            </Grid>
          </Toolbar>
        </AppBar>
        <Toolbar />
        <main className={styles.main}>{children}</main>
      </ContentContainer>
      <CookieBanner />
      <Footer />
    </PageContainer>
  );
};
