import { styled, Theme } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";

export const FooterHolder = styled("footer")(({ theme }) => ({
  position: "absolute",
  bottom: "0",
  width: "100%",
  backgroundColor: "#dedede",
  paddingTop: 20,
  paddingBottom: 10,
  marginTop: 10,
  [theme.breakpoints.down("sm")]: {
    paddingTop: 15,
    paddingBottom: 10,
    overflow: "auto"
  }
}));

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    caption: {
      fontFamily: "Arial",
      fontSize: 13,
      lineHeight: "1.31",
      color: "#333333",
      [theme.breakpoints.down("sm")]: {
        fontSize: 10
      }
    },
    footerContent: {
      [theme.breakpoints.down("sm")]: {
        padding: 10
      }
    },
    links: {
      fontFamily: "Arial",
      color: "#333333",
      display: "inline-block",
      [theme.breakpoints.down("sm")]: {
        fontSize: 12,
        marginLeft: 10,
        marginRight: 5
      },
      [theme.breakpoints.up("md")]: {
        marginBottom: 10,
        fontSize: 14,
        marginLeft: 0,
        marginRight: 10
      }
    }
  })
);
