import React from "react";
import { Skeleton } from "@material-ui/lab";

interface LazyImageProps {
  width: number | string;
  height: number | string;
  imageUrl: string;
  alt?: string;
  className?: string;
}

export const LazyImage: React.FC<LazyImageProps> = ({
  width,
  height,
  imageUrl,
  alt = "",
  className = ""
}) => {
  const [Image, setImg] = React.useState(
    <Skeleton width={width} height={height} />
  );

  React.useEffect(() => {
    fetch(imageUrl).then(r => {
      r.blob().then(b => {
        const url = window.URL.createObjectURL(b);
        setImg(<img src={url} className={className} alt={alt} />);
      });
    });
  }, [alt, className, imageUrl]);

  return Image;
};
