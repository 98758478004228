import React from "react";
import {
  Grid,
  Typography,
  createStyles,
  makeStyles,
  Theme
} from "@material-ui/core";
import { strings } from "src/Constants/Strings";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    scoreContainer: {
      justify: "space-between",
      marginTop: "40px"
    },
    title: {
      color: "#333333",
      fontWeight: "bold",
      fontSize: 20
    },
    lowScore: {
      color: "#bc204b",
      fontWeight: "bold",
      fontSize: 18
    },
    medScore: {
      color: "#f68d2e",
      fontWeight: "bold",
      fontSize: 18
    },
    highScore: {
      color: "#eaaa00",
      fontWeight: "bold",
      fontSize: 18
    },
    topScore: {
      color: "#43b02a",
      fontWeight: "bold",
      fontSize: 18
    },
    scoreText: {
      fontSize: 18
    }
  })
);

export const ScoreBox: React.FC = () => {
  const styles = useStyles();
  return (
    <Grid container xs={8} className={styles.scoreContainer} spacing={2}>
      <Grid item xs={12}>
        <Typography className={styles.title}>
          {strings.results.scoreExplanation}
        </Typography>
      </Grid>

      <Grid item xs={12} md={6}>
        <Typography className={styles.lowScore}>0-8</Typography>
        <Typography className={styles.scoreText}>
          {strings.results.score0}
        </Typography>
      </Grid>

      <Grid item xs={12} md={6}>
        <Typography className={styles.medScore}>9-14</Typography>
        <Typography className={styles.scoreText}>
          {strings.results.score1}
        </Typography>
      </Grid>

      <Grid item xs={12} md={6}>
        <Typography className={styles.highScore}>15-19</Typography>
        <Typography className={styles.scoreText}>
          {strings.results.score2}
        </Typography>
      </Grid>

      <Grid item xs={12} md={6}>
        <Typography className={styles.topScore}>20-24</Typography>
        <Typography className={styles.scoreText}>
          {strings.results.score3}
        </Typography>
      </Grid>
    </Grid>
  );
};
