import React from "react";
import { ButtonClassKey } from "@material-ui/core/Button";
import { CSSProperties } from "@material-ui/styles";
import { AppBarClassKey } from "@material-ui/core/AppBar";
import { TypographyClassKey } from "@material-ui/core/Typography";
import { OutlinedInputClassKey } from "@material-ui/core/OutlinedInput";
import { PaperProps } from "@material-ui/core/Paper";
import { CheckboxProps } from "@material-ui/core/Checkbox";
import { CheckboxChecked } from "src/Icons/CheckboxChecked";
import { CheckboxUnchecked } from "src/Icons/CheckboxUnchecked";

export const buttonOverrides: Partial<Record<ButtonClassKey, CSSProperties>> = {
  root: {
    "&$disabled": {
      opacity: 0.5,
      color: ""
    },
    borderRadius: 0,
    fontFamily: "Arial"
  },
  contained: {
    "&$disabled": {
      color: "",
      backgroundColor: ""
    },
    "&:hover": {
      opacity: 0.8
    }
  }
};

export const outlinedInputOverrides: Partial<
  Record<OutlinedInputClassKey, CSSProperties>
> = {
  root: {
    borderRadius: 0
  }
};

export const appBarOverrides: Partial<Record<AppBarClassKey, CSSProperties>> = {
  root: {
    boxShadow: "0 0 6px 0 rgba(0, 0, 0, 0.2)"
  },
  colorPrimary: {
    backgroundColor: "#ffffff"
  }
};

export const paperDefaultProps: Partial<PaperProps> = {
  square: true
};

export const checkboxDefaultProps: Partial<CheckboxProps> = {
  checkedIcon: <CheckboxChecked />,
  icon: <CheckboxUnchecked />
};

export const typographyOverrides: Partial<
  Record<TypographyClassKey, CSSProperties>
> = {
  h1: {
    fontSize: 34,
    fontFamily: "KPMG",
    fontWeight: 500
  },
  h2: {
    fontSize: 24,
    fontFamily: "Arial"
  },
  h3: {
    fontSize: 22,
    fontFamily: "Arial"
  },
  body1: {
    fontFamily: "Arial"
  }
};
