import React from "react";
import { SvgIcon, SvgIconProps } from "./SvgIcon";

export const Close: React.FC<SvgIconProps> = ({
  width = 17,
  height = 17,
  viewBox = "0 0 17 17",
  ...props
}) => {
  return (
    <SvgIcon width={width} height={height} viewBox={viewBox} {...props}>
      <path
        fill="#333238"
        fillOpacity=".525"
        fillRule="nonzero"
        d="M10.003 8.5l6.685-6.685A1.063 1.063 0 1 0 15.185.312L8.5 6.997 1.815.312A1.063 1.063 0 1 0 .312 1.815L6.997 8.5.312 15.185a1.063 1.063 0 1 0 1.503 1.503L8.5 10.003l6.685 6.685a1.06 1.06 0 0 0 1.503 0 1.063 1.063 0 0 0 0-1.503L10.003 8.5z"
      />
    </SvgIcon>
  );
};

export default Close;
