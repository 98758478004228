import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";
import { HomePage } from "src/Pages/Home";
import { ResultsPage } from "src/Pages/Results";
import { SurveyPage } from "src/Pages/Survey";
import { Layout } from "src/Pages/Layout";
import { useSelector } from "react-redux";
import { AppState } from "./Store/RootReducer";

const App: React.FC = () => {
  const isFinished = useSelector((state: AppState) => state.survey.isFinished);
  return (
    <Router>
      <Layout>
        <Switch>
          <Route
            path="/results"
            render={() => (isFinished ? <ResultsPage /> : <Redirect to="/" />)}
          />
          <Route path="/survey" render={() => <SurveyPage />} />
          <Route path="/" render={() => <HomePage />} />
        </Switch>
      </Layout>
    </Router>
  );
};

export default App;
