import { Page, Option } from "../types";

export interface SurveyState {
  currentPage: number;
  isFinished: boolean;
  pages: Page[];
}

export const MOVE_FORWARD = "@SURVEY/MOVE_FORWARD";
export const MOVE_BACKWARD = "@SURVEY/MOVE_BACKWARD";
export const MOVE_TO_QUESTION = "@SURVEY/MOVE_TO_QUESTION";
export const RESET_SURVEY = "@SURVEY/RESET_SURVEY";
export const ON_COMPLETE_SURVEY = "@SURVEY/ON_COMPLETE_SURVEY";
export const ON_UNDO_COMPLETE_SURVEY = "@SURVEY/ON_UNDO_COMPLETE_SURVEY";
export const ON_ANSWER_CLICKED = "@SURVEY/ON_ANSWER_CLICKED";

export interface MoveForwardAction {
  type: typeof MOVE_FORWARD;
}

export interface MoveBackwardAction {
  type: typeof MOVE_BACKWARD;
}

export interface MoveToQuestionAction {
  type: typeof MOVE_TO_QUESTION;
  number: number;
}

export interface ResetSurveyAction {
  type: typeof RESET_SURVEY;
}

export interface OnCompleteSurveyAction {
  type: typeof ON_COMPLETE_SURVEY;
}

export interface OnUndoCompleteSurveyAction {
  type: typeof ON_UNDO_COMPLETE_SURVEY;
}

export interface OnRadioAnswerClicked {
  type: typeof ON_ANSWER_CLICKED;
  page: number;
  question: number;
  answer: Option;
}

export type ActionTypes =
  | MoveForwardAction
  | MoveBackwardAction
  | MoveToQuestionAction
  | ResetSurveyAction
  | OnCompleteSurveyAction
  | OnUndoCompleteSurveyAction
  | OnRadioAnswerClicked;
