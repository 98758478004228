import { styled } from "@material-ui/core";

export const BarHolder = styled("div")(() => ({
  backgroundColor: "#483698",
  position: "relative",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  height: "20px",
  top: "0"
}));

interface BarsProps {
  color: string;
}

export const Bars = styled("div")(({ color, theme }) => ({
  height: "4px",
  transition: "1000ms ease-in-out",
  backgroundColor: color,
  width: "10.16%",
  [theme.breakpoints.only("xs")]: {
    width: "9%",
    borderRadius: 4
  },
  [theme.breakpoints.only("sm")]: {
    width: "10.5%"
  },
  [theme.breakpoints.up("md")]: {
    width: "11%"
  }
})) as React.ComponentType<BarsProps>;

export const Count = styled("p")(() => ({
  marginLeft: "20px",
  bottom: "25px",
  position: "relative",
  color: "white"
}));
