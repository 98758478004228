import React from "react";
import { SvgIcon, SvgIconProps } from "./SvgIcon";

export const CheckboxChecked: React.FC<SvgIconProps> = ({
  width = 16,
  height = 16,
  viewBox = "0 0 16 16",
  ...props
}) => {
  return (
    <SvgIcon width={width} height={height} viewBox={viewBox} {...props}>
      <g fill="none" fillRule="evenodd">
        <rect width="15" height="15" x=".5" y=".5" stroke="#D4D4D4" rx="4" />
        <path
          fill="#0091DA"
          fillRule="nonzero"
          d="M5.285 7.19L7.533 8.98 11.353 4 13 5.34 7.89 12 4 8.904z"
        />
      </g>
    </SvgIcon>
  );
};

export default CheckboxChecked;
