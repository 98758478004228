import React from "react";
import { SvgIcon, SvgIconProps } from "./SvgIcon";

export const Stopwatch: React.FC<SvgIconProps> = ({
  width = 36,
  height = 34,
  viewBox = "0 0 36 34",
  ...props
}) => {
  return (
    <SvgIcon width={width} height={height} viewBox={viewBox} {...props}>
      <g fill="#FFF" fillRule="evenodd" opacity=".698">
        <path d="M31.658 8.856l.822-.83.726.734c.139.14.363.14.501 0l.5-.506a.36.36 0 0 0 0-.506l-2.506-2.533v-.002a.354.354 0 0 0-.5 0l-.503.508a.363.363 0 0 0 0 .506l.726.734-.857.866v.001a14.396 14.396 0 0 0-7.862-3.35V2.938h1.453a.734.734 0 0 0 .726-.734V.733A.734.734 0 0 0 24.158 0h-5.814a.73.73 0 0 0-.726.734v1.468a.734.734 0 0 0 .726.735h1.453v1.55a14.509 14.509 0 0 0-10.674 6.529.737.737 0 0 0 .763 1.127.725.725 0 0 0 .464-.334 13.072 13.072 0 0 1 8.797-5.757 12.978 12.978 0 0 1 10.155 2.617 13.253 13.253 0 0 1 4.995 9.31c.31 3.67-.913 7.304-3.369 10.027a13.025 13.025 0 0 1-9.573 4.31 13.02 13.02 0 0 1-9.632-4.175.72.72 0 0 0-1.06-.07.738.738 0 0 0 .008 1.077 14.467 14.467 0 0 0 10.255 4.842 14.45 14.45 0 0 0 10.584-4.061A14.755 14.755 0 0 0 36 19.427c.03-3.97-1.531-7.783-4.328-10.57h-.014z" />
        <path d="M20.815 28.581a9.914 9.914 0 0 1-4.72-1.192.739.739 0 0 0-1 .27.71.71 0 0 0 .276.978 11.418 11.418 0 0 0 9.854.463c3.181-1.346 5.55-4.057 6.418-7.346a10.83 10.83 0 0 0-1.981-9.475C27.545 9.589 24.28 8.009 20.815 8c-.4 0-.725.317-.725.71 0 .39.324.709.725.709 3.49 0 6.717 1.826 8.462 4.791a9.413 9.413 0 0 1 0 9.58c-1.745 2.965-4.972 4.791-8.462 4.791z" />
        <path d="M13.23 26.778a.753.753 0 0 0 1.068 0l5.107-5.104a3.046 3.046 0 0 0 3.702-4.78 3.043 3.043 0 0 0-4.02-.255 3.051 3.051 0 0 0-.756 3.96l-5.099 5.095h-.002a.755.755 0 0 0 0 1.084zm7.68-9.191c.607 0 1.157.367 1.389.93a1.503 1.503 0 0 1-1.965 1.966 1.505 1.505 0 0 1 .576-2.896zM3.625 16h3.75c.346 0 .625-.448.625-.999C8 14.448 7.721 14 7.375 14h-3.75C3.28 14 3 14.448 3 15.001c0 .55.28.999.625.999zM13 19c0-.265-.085-.519-.238-.707a.743.743 0 0 0-.574-.293H.812C.362 18 0 18.449 0 19c0 .554.363 1 .812 1h11.376a.747.747 0 0 0 .574-.291c.153-.188.238-.442.238-.709z" />
        <path d="M15.188 22H3.812C3.364 22 3 22.449 3 23c0 .551.364 1 .812 1h11.376c.45 0 .812-.449.812-1 0-.551-.363-1-.812-1zM11 26c0-.267-.08-.52-.22-.709-.14-.186-.331-.291-.531-.291h-7.5C2.337 25 2 25.446 2 26c0 .551.336 1 .75 1h7.499c.2 0 .39-.105.531-.293A1.2 1.2 0 0 0 11 26z" />
      </g>
    </SvgIcon>
  );
};

export default Stopwatch;
