import React from "react";
import { SvgIcon, SvgIconProps } from "./SvgIcon";

export const KpmgLogo: React.FC<SvgIconProps> = ({
  width = 75,
  height = 30,
  viewBox = "0 0 75 30",
  ...props
}) => {
  return (
    <SvgIcon width={width} height={height} viewBox={viewBox} {...props}>
      <path
        fill="#483698"
        fillRule="evenodd"
        d="M58.35 0v14.95c-.526.413-.972.854-1.388 1.33V0h-16.65v13.613h-1.38V0H22.28v13.635h-1.377V0H4.248v15.54L0 29.63h3.737l1.877-6.24h.54l3.099 6.24h4.515l-3.007-6.24h6.823l-1.893 6.24h4.084l1.868-6.222h.896v-.017h9.092l-1.803 6.221h4.117l1.747-6.221h1.853l.05 6.221h3.453l3.964-6.221h2.596l-1.339 6.221h4.054l1.315-6.221h2.344c-.093 1.928.403 3.68 1.69 4.86 1.57 1.435 3.982 1.71 5.767 1.71 2.443 0 4.978-.349 7.53-.92l1.404-5.65H75V0H58.35zM20.302 14.428l-.243.799-2.212 7.323-.088.258h-7.283l-.568-1.193 7.851-7.98h-5.044l-6.14 6.577 1.99-6.577h-3.72V.59h15.457v13.837zm4.299 6.193h-.005c-.15.008-.3.02-.473.02-.226 0-.402.009-.569.009l-.99-.008.458-1.683.217-.836.52-1.958c.23 0 .455-.009.67-.009h.767c1.308 0 2.135.074 2.436.493.231.314.201.857-.071 1.683-.467 1.422-1.057 2.145-2.96 2.29zm11.257 2.187l1.63-5.752.057 5.752h-1.687zm2.476-9.195h-3.871l-2.656 9.195h-4.105c2.092-.77 3.365-2.24 3.758-4.42.325-1.695.17-2.81-.524-3.644-1.041-1.243-3.131-1.136-4.988-1.136l-3.07.005V.591h15.456v13.022zm9.391 9.195h-2.327l3.523-5.512-1.196 5.512zm8.638-8.936l-.008 3.184c-1.078 1.483-1.748 3.1-2.06 4.405-.12.456-.203.899-.248 1.347h-2.273l1.944-9.173-6.542-.006-5.854 9.179h-.427V.59h15.468v13.281zm8.918 12.69c-.884.16-1.764.265-2.603.265-2.23 0-3.782-1.03-3.815-3.436h7.208l-.79 3.172zm9.131-3.754h-3.897l.644-2.563H63.35l-.642 2.563h-3.772v-.526c.058-.29.108-.592.181-.916.687-2.764 2.51-5.495 5.667-5.495 1.248 0 2.489.474 2.32 2.191h4.644c.183-.801.49-2.167-.386-3.422-.99-1.364-2.985-1.911-5.571-1.911-1.84 0-4.53.293-6.855 1.802V.59h15.475v22.217z"
      />
    </SvgIcon>
  );
};

export default KpmgLogo;
