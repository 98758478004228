import React from "react";
import Survey from "src/Components/Survey";
import { AppState } from "src/Store/RootReducer";

export const SurveyPage: React.FC = () => {
  return (
    <div>
      <Survey storeSelector={(state: AppState) => state.survey} />
    </div>
  );
};
