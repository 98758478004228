import React from "react";
import {
  FormControl,
  InputLabel,
  Select as MuiSelect,
  MenuItem
} from "@material-ui/core";
import { SelectProps as MuiSelectProps } from "@material-ui/core/Select";

export interface Option {
  value: number | string;
  text: string;
}

interface SelectProps {
  labelText?: string;
  labelId?: string;
  options?: Option[];
  selectProps?: MuiSelectProps;
  fullWidth?: boolean;
  value: number;
}

export const Select: React.FC<SelectProps> = ({
  labelText = "",
  labelId = "",
  options = [],
  selectProps = {},
  fullWidth = false,
  value
}) => {
  const inputLabel = React.useRef<HTMLLabelElement>(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  React.useEffect(() => {
    if (inputLabel.current !== null) {
      setLabelWidth(inputLabel.current!.offsetWidth);
    }
  }, []);

  return (
    <FormControl variant="outlined" fullWidth={fullWidth}>
      <InputLabel ref={inputLabel} id={labelId}>
        {labelText}
      </InputLabel>
      <MuiSelect
        value={value === 0 ? "" : value}
        labelId={labelId}
        labelWidth={labelWidth}
        fullWidth={fullWidth}
        {...selectProps}
      >
        <MenuItem value={0}>
          <em>None</em>
        </MenuItem>
        {options.map(o => (
          <MenuItem key={o.value} value={o.value}>
            {o.text}
          </MenuItem>
        ))}
      </MuiSelect>
    </FormControl>
  );
};
