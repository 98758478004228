import {
  SurveyState,
  ActionTypes,
  MOVE_FORWARD,
  MOVE_BACKWARD,
  MOVE_TO_QUESTION,
  ON_COMPLETE_SURVEY,
  RESET_SURVEY,
  ON_ANSWER_CLICKED,
  ON_UNDO_COMPLETE_SURVEY
} from "./types";

const initialState: SurveyState = {
  currentPage: 0,
  isFinished: false,
  pages: []
};

export const surveyReducer = (
  state = initialState,
  action: ActionTypes
): SurveyState => {
  switch (action.type) {
    case MOVE_FORWARD:
      if (state.currentPage + 1 === state.pages.length)
        return { ...state, isFinished: true };
      return {
        ...state,
        isFinished: false,
        currentPage: state.currentPage + 1
      };
    case MOVE_BACKWARD:
      if (state.currentPage === 0) return state;
      return {
        ...state,
        isFinished: false,
        currentPage: state.currentPage - 1
      };
    case MOVE_TO_QUESTION:
      return {
        ...state,
        currentPage: action.number
      };
    case ON_ANSWER_CLICKED:
      return {
        ...state,
        pages: state.pages.map((p, i) => {
          if (i !== action.page) return p;

          return {
            ...p,
            questions: p.questions.map((q, i) => {
              if (i !== action.question) return q;
              return {
                ...q,
                currentAnswer: action.answer
              };
            })
          };
        })
      };
    case RESET_SURVEY:
      return initialState;

    case ON_COMPLETE_SURVEY:
      return {
        ...state,
        isFinished: true
      };
    case ON_UNDO_COMPLETE_SURVEY:
      return {
        ...state,
        isFinished: false
      };
    default:
      return state;
  }
};
