import React from "react";
import { makeStyles, createStyles } from "@material-ui/styles";
import { strings } from "src/Constants/Strings";
import { Typography, Link, Grid, Theme, Button } from "@material-ui/core";
import { cookiesLink } from "src/Constants/Links";
import AggreeChevron from "src/Icons/AgreeChevron";
import { useCookies } from "react-cookie";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      paddingTop: 10,
      position: "fixed",
      bottom: 0,
      zIndex: 100,
      backgroundColor: "#f6f6f6",
      borderTop: "2px solid #999999"
    },
    grid: {
      [theme.breakpoints.down("sm")]: {
        padding: 10
      }
    },
    text: {
      color: "#333333",
      fontSize: 14,
      display: "inline"
    },
    link: {
      fontSize: 14,
      display: "inline"
    },
    agreeButton: {
      background: "transparent",
      border: "none",
      fontSize: 16,
      marginBottom: 10,
      textTransform: "none",
      color: "#666666"
    }
  })
);

export const CookieBanner = () => {
  const styles = useStyles();
  const [cookies, setCookie, removeCookie] = useCookies([
    "agreed_cookie_banner"
  ]);

  const [isAgreed, setIsAgreed] = React.useState(false);

  React.useEffect(() => {
    const cookieExists = cookies["frm-agreed-cookie-banner"];
    if (cookieExists) {
      setIsAgreed(true);
    }
  }, [setIsAgreed, cookies]);

  const onAgreeClick = React.useCallback(() => {
    setIsAgreed(true);
    setCookie("frm-agreed-cookie-banner", true, { sameSite: "strict" });
  }, []);

  if (isAgreed) return <div />;

  return (
    <div className={styles.root}>
      <Grid container justify="center" className={styles.grid}>
        <Grid item xs={12} md={10}>
          <Typography className={styles.text}>
            {strings.layout.cookiesText1}
          </Typography>
          <Link
            href={cookiesLink.href}
            target="_blank"
            color="secondary"
            display="inline"
            className={styles.link}
          >
            {cookiesLink.text}
          </Link>
          <Typography className={styles.text}>
            {strings.layout.cookiesText2}
          </Typography>
        </Grid>
        <Grid item xs={12} md={10}>
          <Grid container justify="flex-end">
            <Button className={styles.agreeButton} onClick={onAgreeClick}>
              <AggreeChevron style={{ marginRight: 10 }} />
              {strings.layout.iAgree}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
