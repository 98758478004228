import React from "react";
import { PageProps, onUndoCompleteSurvey } from "src/Components/Survey";
import { SubmissionDialog } from "./SubmissionDialog";
import { useDispatch } from "react-redux";

export const SurveyPage: React.FC<PageProps> = ({ children, isFinished }) => {
  const dispatch = useDispatch();
  const onClose = React.useCallback(() => dispatch(onUndoCompleteSurvey()), [
    dispatch
  ]);
  return (
    <>
      <SubmissionDialog isFinished={isFinished} onClose={onClose} />
      {children}
    </>
  );
};
