import { createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { AppState, rootReducer } from "./RootReducer";

export const configureStore = (initialState: Partial<AppState> = {}) => {
  const middlewares = [thunkMiddleware];
  const middleWareEnhancer = applyMiddleware(...middlewares);

  const store = createStore(
    rootReducer,
    initialState,
    process.env.NODE_ENV !== "production"
      ? composeWithDevTools(middleWareEnhancer)
      : middleWareEnhancer
  );

  return store;
};
