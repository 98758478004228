import React from "react";
import { styled } from "@material-ui/core";

interface HorizontalLineProps {
  width?: number | string;
  height?: number | string;
  backgroundColor?: string;
  style?: React.CSSProperties;
}

export const HorizontalLine = styled("hr")(
  ({
    height = 1,
    width = "100%",
    backgroundColor = "#c6c6c6",
    style = {}
  }) => ({
    width: width,
    height: height,
    backgroundColor: backgroundColor,
    padding: 0,
    borderStyle: "none",
    ...style
  })
) as React.ComponentType<HorizontalLineProps>;
