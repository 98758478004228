export const strings = {
  layout: {
    title: "Fraud Risk Questionnaire",
    footerText:
      "© 2021 KPMG LLP a UK limited liability partnership and a member firm of the KPMG global organisation of independent member firms affiliated with KPMG International Limited, a private English company limited by guarantee. All rights reserved.",
    footerText2:
      "For more detail about the structure of the KPMG global organisation please visit ",
    footerGovernanceLink: "https://home.kpmg/governance",
    disclaimer:
      "The information contained herein is of a general nature and is not intended to address the circumstances of any particular individual or entity. Although we endeavour to provide accurate and timely information, there can be no guarantee that such information is accurate as of the date it is received or that it will continue to be accurate in the future. No one should act upon such information without appropriate professional advice after a thorough examination of the particular situation.",
    cookiesText1:
      "This website uses cookies that provide necessary site functionality and improve your online experience. By continuing to use this website, you agree to the use of cookies. Our ",
    cookiesText2:
      " provides more information about what cookies we use and how you can change them.",
    iAgree: "I agree"
  },
  home: {
    title: "How well does your organisation manage fraud risk?",
    introBold:
      "Fraud can damage the financial health of any business, as well as its image and reputation.",
    intro:
      "It is estimated that up to 5% of business revenues are lost to fraud, and in some cases it can lead to job losses or even the collapse of the business. Fraud is a serious business risk and should be high on the agenda of every organisation. Planning for fraud and taking steps to reduce its likelihood and impact on the business makes good sense.",
    intro2:
      "Please complete this short questionnaire to see how well your organisation is managing the risk of fraud. ",
    getScore: "Get your risk score",
    timeTaken: "Typically takes less than 5 minutes to complete"
  },
  results: {
    title:
      "Thank you for completing the fraud risk survey. Your score and summary of responses are listed below. ",
    scoreTitle: "Your fraud risk score is",
    scoreExplanation: "Score Explanation",
    contactDetails:
      "Get in touch with us to see how we can help you better manage your risks. ",
    contactEmailText: "Email our forensic team",
    contactEmail: "ukfmfrrimansur@kpmg.co.uk",
    summaryTitle: "Summary of your responses",
    infoQuote1:
      "Financial, commercial and reputational loss is a constant risk for both public and private sector organisations.",
    infoQuote2:
      "We help our clients take immediate and decisive action to prevent, detect and respond to potentially contentious or harmful situations.",
    infoQuoteName: "Damien Margetson",
    infoQuoteTitle: "Director, KPMG Forensic",
    score0:
      "The actions taken by your organisation to prevent fraud are very weak. There is an extreme risk that you might fail to detect a fraud before it became material.",
    score1:
      "The actions taken by your organisation to prevent fraud are limited. There is a high risk that you might fail to detect a fraud before it became material.",
    score2:
      "The actions taken by your organisation to prevent fraud are reasonable, but gaps remain. There is some risk that you might fail to detect a fraud before it became material.",
    score3:
      "The actions taken by your organisation to prevent fraud are good. However, you should ensure that your anti-fraud practices are updated regularly."
  },
  survey: {
    question1: {
      question:
        "How well do you feel that your organisation has assigned the responsibility for preventing fraud?",
      option0:
        "No discussion has ever taken place to assign responsibility for fraud.",
      option1:
        "Discussions have taken place but the responsibility has not been formally assigned.",
      option2:
        "We have assigned responsibility for fraud but have no written job specification.",
      option3:
        "We have assigned responsibility for fraud and have written job specifications."
    },
    question2: {
      question:
        "Do you understand the fraud risks facing your organisation and their potential impact?",
      option0: "No assessment of fraud risk has ever taken place.",
      option1: "We assess fraud risk on an ad-hoc basis.",
      option2:
        "A documented fraud risk assessment has been undertaken in all key areas of the business.",
      option3:
        "We regularly assess fraud risk in all key areas of the business, strengthening the control structure as necessary."
    },
    question3: {
      question:
        "How effective do you feel your organisation is at providing training and awareness on how to combat fraud?",
      option0: "No specific fraud awareness training is provided.",
      option1:
        "Staff are warned about fraud on their induction but no further training occurs.",
      option2: "Ad-hoc fraud training occurs.",
      option3:
        "We ensure that all members of staff attend an annual update on fraud."
    },
    question4: {
      question:
        "Does your organisation have a fraud policy which provides guidance on unacceptable behaviour and potential disciplinary actions?",
      option0: "No documented fraud policy exists.",
      option1:
        "We have a fraud policy document but it does not cover the above.",
      option2:
        "We have a fraud policy document that touches on examples of unacceptable behaviour.",
      option3:
        "Our fraud policy documents covers both examples of unacceptable behaviour and potential disciplinary actions."
    },
    question5: {
      question:
        "Does your organisation advise its employees on how they can report suspicions of fraud and inappropriate behaviour?",
      option0: "There is no formal reporting policy.",
      option1:
        "We have no formal reporting policy but I am convinced that all staff know who to contact.",
      option2:
        "We have a formal reporting policy but I am not convinced that all staff are aware of it.",
      option3:
        "We have a formal reporting policy and have had staff use these channels in the past to report suspicions of fraud."
    },
    question6: {
      question:
        "Does your organisation provide protection for employees who raise concerns about fraud and inappropriate behaviour?",
      option0: "No formal protections exist.",
      option1:
        "We assure staff after they have reported a fraud that they will receive adequate protection.",
      option2:
        "We have verbally told all staff that they will be protected when reporting fraud.",
      option3:
        "Our formal reporting policy details all the protections available to staff reporting fraud."
    },
    question7: {
      question:
        "How thoroughly do you believe your organisation checks the background of potential new recruits?",
      option0:
        "Other than an interview we undertake no background checks on potential recruits.",
      option1:
        "We seek references for each recruit but perform no other background checks.",
      option2:
        "We undertake background checks on qualifications and employment history for senior posts only.",
      option3:
        "We undertake background checks on qualifications and employment history for all potential recruits."
    },
    question8: {
      question:
        "How effective do you believe your organisation would be if a significant fraud is discovered?",
      option0: "We would not know what to do in this situation.",
      option1: "We would immediately call the Police.",
      option2:
        "We have had discussions about the best way to respond to allegations of significant fraud.",
      option3:
        "We have a documented fraud response plan which we would follow in the event that a significant fraud was alleged."
    }
  },
  submissionDialog: {
    title: "Fill in the form to get your self-assessment score!",
    firstName: "First name",
    surname: "Surname",
    email: "Email",
    country: "Country",
    company: "Company",
    dropdownLabel: "Industry / sector",
    terms0:
      "The results of this survey are based entirely on the responses that you have given and KPMG takes no responsibility for these, nor is it obliged to contact you following completion of the survey.",
    terms1:
      "By providing your email address you consent to KPMG in the UK processing your data for the purposes of sending you whitepapers, reports, or brochures via email.",
    terms2:
      "By providing your geographic location, you consent to KPMG UK sharing your personal data with the respective KPMG member firm within the stated country, to facilitate follow up correspondence in relation to your submission.",
    terms3:
      "We may use your contact information to identify if our products and services are relevant for you unless you tell us not to ",
    terms4:
      "To learn more about how we respect and protect your personal data please see our online ",
    buttonText: "View survey results",
    firstNameRequired: "First name is required",
    surnameRequired: "Surname is required",
    emailRequired: "Email is required",
    industryRequired: "Industry is required",
    companyRequired: "Company is required",
    countryRequired: "Country is required",
    maxLengthExceeded: "Max length exceeded",
    invalidEmail: "Must be a valid email address"
  },
  common: {
    back: "Back"
  }
};
