import React from "react";
import { Grid, Typography, Theme } from "@material-ui/core";
import { strings } from "src/Constants/Strings";
import { makeStyles, createStyles } from "@material-ui/styles";
import { Summary } from "./Summary";
import { InfoBox } from "./InfoBox";
import { ResultsBox } from "./ResultsBox";
import { ScoreBox } from "./ScoreBox";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titlePanel: {
      [theme.breakpoints.up("sm")]: {
        height: 180
      },
      paddingTop: 35,
      width: "100%",
      textAlign: "center",
      backgroundColor: theme.palette.primary.main
    },
    titleText: {
      textAlign: "left",
      lineHeight: "1.42",
      color: "#ffffff",
      fontSize: 24,
      [theme.breakpoints.down("sm")]: {
        fontSize: 22
      }
    }
  })
);

export const ResultsPage: React.FC = () => {
  const styles = useStyles();
  return (
    <Grid container justify="center">
      <Grid container className={styles.titlePanel} justify="center">
        <Grid item xs={8} md={8}>
          <Typography variant="h2" className={styles.titleText}>
            {strings.results.title}
          </Typography>
        </Grid>
      </Grid>
      <ResultsBox />
      <ScoreBox />
      <InfoBox />
      <Summary />
    </Grid>
  );
};
