import { SimplePaletteColorOptions } from "@material-ui/core/styles";
import {
  TypeBackground,
  PaletteOptions,
  TypeText
} from "@material-ui/core/styles/createPalette";

export const primaryPalette: SimplePaletteColorOptions = {
  main: "#483698",
  dark: "#483698",
  light: "#483698",
  contrastText: "#ffffff"
};

export const secondaryPalette: SimplePaletteColorOptions = {
  main: "#0091da",
  dark: "#0091da",
  light: "#0091da",
  contrastText: "#ffffff"
};

export const backgroundPalette: TypeBackground = {
  default: "#efefef",
  paper: "#ffffff"
};

export const textPalette: Partial<TypeText> = {
  primary: "#333333"
};

export const paletteOptions: PaletteOptions = {
  primary: primaryPalette,
  secondary: secondaryPalette,
  background: backgroundPalette
  // text: textPalette
};
