import React from "react";
import { Grid, Typography, Link } from "@material-ui/core";
import { strings } from "src/Constants/Strings";
import { FooterHolder, useStyles } from "./FooterStyle";
import { footerLegalLink, footerPrivacyLink } from "src/Constants/Links";

const Footer: React.FC = () => {
  const styles = useStyles();
  return (
    <FooterHolder>
      <Grid container justify="center">
        <Grid item xs={12} md={10}>
          <Link
            href={footerLegalLink.href}
            color="secondary"
            display="inline"
            className={styles.links}
            target="_blank"
          >
            {footerLegalLink.text}
          </Link>
          <Link
            href={footerPrivacyLink.href}
            color="secondary"
            display="inline"
            className={styles.links}
            target="_blank"
          >
            {footerPrivacyLink.text}
          </Link>
        </Grid>
        <Grid item xs={12} md={10} className={styles.footerContent}>
          <Typography className={styles.caption}>
            {strings.layout.footerText}
          </Typography>
          <br />
          <Typography className={styles.caption}>
            {strings.layout.footerText2}{" "}
            <a href={strings.layout.footerGovernanceLink} target="_blank">
              {strings.layout.footerGovernanceLink}
            </a>
          </Typography>
          <br />
          <Typography className={styles.caption}>
            {strings.layout.disclaimer}
          </Typography>
        </Grid>
      </Grid>
    </FooterHolder>
  );
};

export default Footer;
