import { Option } from "src/Components/Select/Select";

export const industries: Option[] = [
  { value: 1, text: "Energy and Natural Resources" },
  { value: 2, text: "Industrial Manufacturing" },
  { value: 3, text: "Life Sciences" },
  { value: 4, text: "Consumer Markets" },
  { value: 5, text: "Media" },
  { value: 6, text: "Technology" },
  { value: 7, text: "Telco" },
  { value: 8, text: "Leisure" },
  { value: 9, text: "Banking" },
  { value: 10, text: "Insurance" },
  { value: 11, text: "Investment Management" },
  { value: 12, text: "Government" },
  { value: 13, text: "Transport" },
  { value: 14, text: "Infrastructure" },
  { value: 15, text: "Healthcare" },
  { value: 16, text: "Business Services" },
  { value: 17, text: "Miscellaneous" },
  { value: 18, text: "Private Equity" }
];
