import { RadioAnswer } from "src/Components/Survey";

export interface SubmissionState {
  loading: boolean;
  success: boolean;
  error: string | null;
}

export interface Result {
  question: string;
  answer: RadioAnswer;
}

export interface Submission {
  firstName: string;
  surname: string;
  email: string;
  company: string;
  industry: string;
  country: string;
  captchaCode: string;
  results: Result[];
}

export const ON_SUBMIT_RESULTS_BEGIN = "ON_SUBMIT_RESULTS_BEGIN";
export interface OnSubmitResultsBeginAction {
  type: typeof ON_SUBMIT_RESULTS_BEGIN;
}

export const ON_SUBMIT_RESULTS_SUCCESS = "ON_SUBMIT_RESULTS_SUCCESS";
export interface OnSubmitResultsSuccessAction {
  type: typeof ON_SUBMIT_RESULTS_SUCCESS;
}

export const ON_SUBMIT_RESULTS_ERROR = "ON_SUBMIT_RESULTS_ERROR";
export interface OnSubmitResultsErrorAction {
  type: typeof ON_SUBMIT_RESULTS_ERROR;
  message: string;
}

export type SubmissionActionTypes =
  | OnSubmitResultsBeginAction
  | OnSubmitResultsSuccessAction
  | OnSubmitResultsErrorAction;
