import React from "react";
import {
  RadioQuestionComponentProps,
  Option,
  moveForward,
  moveBackward,
  onRadioAnswerClicked
} from "src/Components/Survey";
import { useDispatch } from "react-redux";
import { ProgressBar } from "src/Components/ProgressBar/ProgressBar";
import { useHistory } from "react-router";
import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, Grid, Typography } from "@material-ui/core";
import { RadioQuestionButton } from "src/Components/RadioQuestionButton/RadioQuestionButton";
import { BackButton } from "src/Components/BackButton/BackButton";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    questionContainer: {
      backgroundColor: theme.palette.primary.main,
      [theme.breakpoints.up("md")]: {
        paddingTop: 35
      },
      [theme.breakpoints.down("sm")]: {
        paddingLeft: 30,
        paddingRight: 30,
        paddingTop: 25,
        paddingBottom: 20
      }
    },
    questionText: {
      color: theme.palette.background.paper,
      fontSize: 24,
      [theme.breakpoints.down("sm")]: {
        fontSize: 22
      }
    },
    progressBarContainer: {
      marginTop: 55
    },
    answerContainer: {
      marginTop: 55
    },
    backButton: {
      marginTop: 50,
      marginBottom: 80,
      [theme.breakpoints.down("sm")]: {
        marginLeft: 30
      }
    }
  })
);

export const RadioQuestionComponent: React.FC<RadioQuestionComponentProps> = ({
  question,
  pageProps: { questionNumber, pageNumber, totalPages }
}) => {
  const dispatch = useDispatch();

  const currentAnswerValue =
    question.currentAnswer !== undefined ? question.currentAnswer.value : -1;

  const onNext = React.useCallback(
    (answer: Option) => {
      dispatch(moveForward());
      dispatch(onRadioAnswerClicked(pageNumber, questionNumber, answer));
    },
    [dispatch, pageNumber, questionNumber]
  );
  const history = useHistory();

  const onBack = React.useCallback(() => {
    dispatch(moveBackward());
    if (pageNumber === 0) {
      history.push("/");
    }
  }, [dispatch, pageNumber, history]);

  const styles = useStyles();

  return (
    <Grid container justify="center">
      <Grid item xs={12}>
        <Grid container justify="center" className={styles.questionContainer}>
          <Grid item md={8} xs={12}>
            <Typography variant="h2" className={styles.questionText}>
              {question.text}
            </Typography>
            <div className={styles.progressBarContainer}>
              <ProgressBar currentPage={pageNumber} />
            </div>
          </Grid>
        </Grid>
        <Grid container justify="center">
          <Grid item xs={12} md={8}>
            <Grid
              container
              className={styles.answerContainer}
              alignItems="stretch"
            >
              {question.options.map((option, i) => (
                <RadioQuestionButton
                  onClick={onNext}
                  key={i}
                  option={option}
                  isCurrentAnswer={currentAnswerValue === option.value}
                />
              ))}
            </Grid>
            <Grid container>
              <BackButton
                onClick={onBack}
                className={styles.backButton}
                buttonProps={{ tabIndex: 0 }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
