import React from "react";
import { SvgIcon, SvgIconProps } from "./SvgIcon";

export const RadioOff: React.FC<SvgIconProps> = ({
  width = 22,
  height = 22,
  viewBox = "0 0 22 22",
  ...props
}) => {
  return (
    <SvgIcon width={width} height={height} viewBox={viewBox} {...props}>
      <path
        fill="#999"
        fillRule="evenodd"
        d="M10 0C4.5 0 0 4.5 0 10s4.5 10 10 10 10-4.5 10-10S15.5 0 10 0zm0 18c-4.4 0-8-3.6-8-8s3.6-8 8-8 8 3.6 8 8-3.6 8-8 8z"
        opacity=".54"
      />
    </SvgIcon>
  );
};

export default RadioOff;
