import React from "react";
import { Paper, Grid, Theme, Link, Typography } from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import { resultsPageLinks, Link as ILink } from "src/Constants/Links";
import { LazyImage } from "src/Components/Image/LazyImage";
import { strings } from "src/Constants/Strings";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    panel: {
      backgroundColor: "#f0f0f0",
      boxShadow: "none",
      marginTop: 50,
      marginBottom: 100,
      padding: 25
    },
    link: {
      marginBottom: 25
    },
    infoQuote: {
      marginBottom: 10,
      fontWeight: 300
    },
    articleLink: {
      fontSize: "16px",
      lineHeight: 1.44,
      color: "black",
      fontWeight: "bold",
      textDecoration: "underline"
    },
    articleText: {
      fontSize: "16px",
      lineHeight: 1.44,
      color: "#333333",
      fontweight: 300
    }
  })
);

const imgLocation = `${window.location.origin}/results-photo.jpg`;

export const InfoBox: React.FC = () => {
  const styles = useStyles();

  const onLinkClick = React.useCallback(
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, link: ILink) => {
      e.preventDefault();
      window.open(link.href);
    },
    []
  );

  return (
    <Grid item md={8} xs={8}>
      <Paper className={styles.panel}>
        <Grid container spacing={5}>
          <Grid item lg={4} md={3} xs={12}>
            {resultsPageLinks.map(l => (
              <div
                key={l.text}
                className={styles.link}
                onClick={(e: any) => onLinkClick(e, l)}
                style={{ cursor: "pointer" }}
              >
                <Link
                  href="#"
                  onClick={(e: any) => onLinkClick(e, l)}
                  className={styles.articleLink}
                >
                  {l.text}
                </Link>
                <Typography className={styles.articleText}>{l.desc}</Typography>
              </div>
            ))}
          </Grid>
          <Grid item lg={3} md={4} xs={12}>
            <LazyImage width={180} height={205} imageUrl={imgLocation} />
          </Grid>
          <Grid item lg={5} md={5} xs={12}>
            <Typography className={styles.infoQuote}>
              {strings.results.infoQuote1}
            </Typography>
            <Typography className={styles.infoQuote}>
              {strings.results.infoQuote2}
            </Typography>
            <Typography style={{ fontWeight: "normal" }}>
              {strings.results.infoQuoteName}
            </Typography>
            <Typography style={{ fontWeight: 300 }}>
              {strings.results.infoQuoteTitle}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};
