import React from "react";
import { makeStyles, createStyles } from "@material-ui/styles";
import BackArrow from "src/Icons/BackArrow";
import { Typography, Theme } from "@material-ui/core";
import { strings } from "src/Constants/Strings";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexDirection: "row",
      cursor: "pointer",
      border: "none",
      backgroundColor: theme.palette.background.paper
    },
    text: {
      fontSize: 16,
      color: "#666666",
      lineHeight: "18px",
      marginLeft: 12.5
    }
  })
);

interface BackButtonProps {
  onClick: () => void;
  className?: string;
  buttonProps?: React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >;
}

export const BackButton: React.FC<BackButtonProps> = ({
  onClick,
  className,
  buttonProps = {}
}) => {
  const styles = useStyles();
  return (
    <button
      className={`${className} ${styles.root}`}
      onClick={onClick}
      {...buttonProps}
    >
      <BackArrow />
      <Typography className={styles.text}>{strings.common.back}</Typography>
    </button>
  );
};
