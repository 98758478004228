import { styled } from "@material-ui/core";

interface PageContainerProps {
  backgroundColor: string;
}
export const PageContainer = styled("div")(({ backgroundColor }) => ({
  minHeight: "100vh",
  position: "relative",
  backgroundColor: backgroundColor
})) as React.ComponentType<PageContainerProps>;

export const ContentContainer = styled("div")(() => ({
  paddingBottom: "120px"
}));
