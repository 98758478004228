import {
  ON_SUBMIT_RESULTS_BEGIN,
  ON_SUBMIT_RESULTS_SUCCESS,
  OnSubmitResultsBeginAction,
  Submission,
  OnSubmitResultsSuccessAction,
  OnSubmitResultsErrorAction,
  ON_SUBMIT_RESULTS_ERROR
} from "./types";
import { ApiClient } from "src/Store/Api/ApiClient";
import { endpoints } from "src/Store/Api/ApiEndpoints";
import { Dispatch } from "redux";
import { BAD_REQUEST } from "src/Store/Api/StatusCodes";

export const onSubmitResultsBegin = (): OnSubmitResultsBeginAction => ({
  type: ON_SUBMIT_RESULTS_BEGIN
});

export const onSubmitResultsSuccess = (): OnSubmitResultsSuccessAction => ({
  type: ON_SUBMIT_RESULTS_SUCCESS
});

export const onSubmitResultsError = (
  message: string
): OnSubmitResultsErrorAction => ({
  type: ON_SUBMIT_RESULTS_ERROR,
  message
});

export const onSubmitResults = (submission: Submission) => {
  return (dispatch: Dispatch) => {
    dispatch(onSubmitResultsBegin());
    var client = new ApiClient();
    client.post(endpoints.submission, submission).then(response => {
      response.json().then(body => {
        if (body.ok) {
          dispatch(onSubmitResultsSuccess());
        } else {
          switch (body.statusCode) {
            case BAD_REQUEST:
              dispatch(onSubmitResultsError(body.body.message));
              break;
            default:
              dispatch(onSubmitResultsError("Something went wrong"));
          }
        }
      });
    });
  };
};
