import React from "react";
import { Button } from "@material-ui/core";
import { ButtonProps } from "@material-ui/core/Button";

interface CaptchaButtonProps {
  buttonProps?: ButtonProps;
  onCaptchaComplete: (response: string) => void;
  siteKey: string;
}

export class CaptchaButton extends React.PureComponent<CaptchaButtonProps> {
  state = {
    widgetId: -1
  };

  placeholder = React.createRef<HTMLDivElement>();
  componentWillMount = () => {
    // Workaround to avoid invoking the actual captcha during cypress testing
    // This will not work in a live environment as it will not provide a real
    // Captcha response which is needed by the backend
    // @ts-ignore-2339
    if (window.Cypress)
      // @ts-ignore-2339
      window.captchaClick = () => this.props.onCaptchaComplete("code");

    this.onPrepareCaptcha();
  };

  componentWillUnmount = () => {
    const { widgetId } = this.state;
    if (window.grecaptcha && widgetId > -1) {
      window.grecaptcha.reset(widgetId);
    }
  };

  onPrepareCaptcha = () => {
    if (!window.grecaptcha) {
      const script = document.createElement("script");
      script.src = "https://www.google.com/recaptcha/api.js";
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
      script.addEventListener("load", this.onLoadCaptcha);
    } else {
      this.onLoadCaptcha();
    }
  };

  onLoadCaptcha = () => {
    if (
      !window.grecaptcha ||
      !window.grecaptcha.render ||
      !this.placeholder.current
    ) {
      setTimeout(() => {
        this.onLoadCaptcha();
      }, 500);
    } else {
      const widgetId = window.grecaptcha.render(
        this.placeholder.current as HTMLDivElement,
        {
          sitekey: this.props.siteKey,
          size: "invisible",
          callback: this.onCompleteCaptcha
        }
      );
      this.setState({ widgetId });
    }
  };

  onExecuteCaptcha = () => {
    window.grecaptcha.execute();
  };

  onCompleteCaptcha = (response: string) => {
    this.props.onCaptchaComplete(response);
  };

  render() {
    const { buttonProps = {}, children } = this.props;
    return (
      <div>
        <div ref={this.placeholder} />
        <Button onClick={this.onExecuteCaptcha} {...buttonProps}>
          {children}
        </Button>
      </div>
    );
  }
}
