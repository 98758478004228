import { strings } from "./Strings";

export interface Link {
  text: string;
  href: string;
  desc: string;
}

export const resultsPageLinks: Link[] = [
  {
    href:
      "https://home.kpmg/uk/en/home/services/advisory/risk-consulting/forensic-landing.html",
    text: "KPMG Forensic Home Page",
    desc:
      "Latest news, reports, statistics and information about KPMG services."
  },
  {
    text: "The Fraud Barometer",
    href: "https://home.kpmg/uk/en/home/insights/2015/12/fraud-barometer.html",
    desc: "The latest fraud trends and patterns affecting the UK economy."
  }
];

export const privacyPolicy: Link = {
  text: "Privacy Policy",
  desc: "",
  href: "https://home.kpmg/uk/en/home/misc/privacy.html"
};

export const objectToProfiling: Link = {
  text: "here",
  desc: "",
  href: `mailto:${strings.results.contactEmail}?subject=Please%20do%20not%20provide%20me%20with%20recommendations%20regarding%20your%20products%20and%20services`
};

export const footerLegalLink: Link = {
  text: "Legal",
  desc: "",
  href: "https://home.kpmg/uk/en/home/misc/legal.html"
};

export const footerPrivacyLink: Link = {
  text: "Privacy",
  desc: "",
  href: "https://home.kpmg/uk/en/home/misc/privacy.html"
};

export const cookiesLink: Link = {
  text: "cookies notice",
  desc: "",
  href: "https://home.kpmg/uk/en/home/misc/privacy.html"
};
