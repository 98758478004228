import {
  SubmissionState,
  SubmissionActionTypes,
  ON_SUBMIT_RESULTS_BEGIN,
  ON_SUBMIT_RESULTS_SUCCESS,
  ON_SUBMIT_RESULTS_ERROR
} from "./types";

const initialState: SubmissionState = {
  loading: false,
  success: false,
  error: null
};

export const submissionReducer = (
  state = initialState,
  action: SubmissionActionTypes
): SubmissionState => {
  switch (action.type) {
    case ON_SUBMIT_RESULTS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null
      };
    case ON_SUBMIT_RESULTS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true
      };
    case ON_SUBMIT_RESULTS_ERROR:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.message
      };
    default:
      return state;
  }
};
