import React from "react";
import { SvgIcon, SvgIconProps } from "./SvgIcon";

export const BackArrow: React.FC<SvgIconProps> = ({
  width = 11,
  height = 16,
  viewBox = "0 0 11 16",
  ...props
}) => {
  return (
    <SvgIcon width={width} height={height} viewBox={viewBox} {...props}>
      <path
        fill="#848484"
        fillRule="nonzero"
        d="M7.929 0l2.5 2.5-5.5 5.5 5.5 5.5-2.5 2.5-8-8z"
      />
    </SvgIcon>
  );
};

export default BackArrow;
