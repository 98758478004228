import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "./index.css";
import App from "./App";
import { ThemeProvider } from "@material-ui/styles";
import { theme } from "src/Theme/Theme";
import { configureStore } from "src/Store/ConfigureStore";
import { initialState } from "src/Constants/SurveyQuestions";

const store = configureStore(initialState);

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </Provider>,
  document.getElementById("root")
);
