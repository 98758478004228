import React, { useMemo } from "react";
import {
  Dialog,
  Theme,
  Typography,
  TextField,
  Button,
  Checkbox,
  LinearProgress,
  Link
} from "@material-ui/core";
import { makeStyles, createStyles } from "@material-ui/styles";
import Close from "src/Icons/Close";
import { strings } from "src/Constants/Strings";
import { industries } from "src/Constants/Industries";
import { useDispatch, useSelector } from "react-redux";
import { onSubmitResults } from "src/Store/Areas/Submission/action";
import { AppState } from "src/Store/RootReducer";
import { RadioAnswer } from "src/Components/Survey/Questions/RadioQuestion";
import { CaptchaButton } from "src/Components/Captcha/CaptchaButton";
import { useFormik } from "formik";
import {
  submissionValidation,
  submissionInitialState
} from "src/Constants/SubmissionValidation";
import { Redirect } from "react-router";
import { Select, Option } from "src/Components/Select/Select";
import { OutlinedTextFieldProps } from "@material-ui/core/TextField";
import { Blur } from "src/Components/Blur/Blur";
import { privacyPolicy, objectToProfiling } from "src/Constants/Links";
import { Autocomplete } from "@material-ui/lab";
import { countries } from "src/Constants/Countries";

interface SubmissionDialogProps {
  isFinished: boolean;
  onClose: () => void;
}

const siteKey = process.env.REACT_APP_CAPTCHA_SITE_KEY as string;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      overflow: "hidden"
    },
    dialogContent: {
      [theme.breakpoints.up("sm")]: {
        width: 500
      },
      padding: 30,
      overflow: "auto"
    },
    close: {
      float: "right",
      cursor: "pointer"
    },
    textField: {
      marginBottom: 25,
      display: "block"
    },
    header: {
      marginBottom: 35,
      fontSize: 22
    },
    checkBox: {
      marginTop: 30,
      display: "flex",
      flexDirection: "row",
      textTransform: "none",
      textAlign: "left",
      padding: 0,
      "&:hover": {
        backgroundColor: "transparent"
      }
    },
    termsAndConditions: {
      fontSize: 12,
      color: "#909090"
    },
    conditionsMargin: {
      marginTop: 10,
      marginLeft: 34
    },
    submitButton: {
      marginTop: 30,
      marginBottom: 10,
      height: 44,
      width: 180,
      textTransform: "none",
      fontSize: 16
    },
    loader: {
      zIndex: 11,
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%"
    }
  })
);

export const SubmissionDialog: React.FC<SubmissionDialogProps> = ({
  isFinished,
  onClose
}) => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: submissionInitialState,
    validateOnBlur: true,
    validationSchema: submissionValidation,
    isInitialValid: false,
    onSubmit: values => {
      alert(JSON.stringify(values, null, 2));
    }
  });

  const submission = useSelector((state: AppState) => state.submission);
  const results = useSelector((state: AppState) =>
    state.survey.pages.map(p => ({
      question: p.questions[0].text,
      answer: p.questions[0].currentAnswer as RadioAnswer
    }))
  );

  const countryOptions = useMemo(() => countries.map(x => x.text), []);

  const onSubmit = React.useCallback(
    (captchaCode: string) =>
      dispatch(
        onSubmitResults({
          firstName: formik.values.firstName,
          surname: formik.values.surname,
          email: formik.values.email,
          company: formik.values.company,
          industry: (industries.find(
            i => i.value === formik.values.industry
          ) as Option).text,
          country: formik.values.country,
          captchaCode,
          results: results
        })
      ),
    [dispatch, results, formik]
  );
  if (!submission.loading && submission.success) {
    return <Redirect to="/results" />;
  }
  const withTextFieldProps = (
    field: "firstName" | "surname" | "email" | "company" | "country"
  ): Partial<OutlinedTextFieldProps> => {
    const error = formik.errors[field];
    const showError = formik.values[field] !== "" && error !== undefined;
    return {
      name: field,
      error: showError,
      helperText: showError ? error : "",
      value: formik.values[field],
      fullWidth: true,
      className: styles.textField,
      onChange: (event: any) => {
        formik.setFieldValue(field, event.target.value);
      }
    };
  };

  return (
    <Dialog open={isFinished} className={styles.root}>
      <LinearProgress
        className={styles.loader}
        variant="indeterminate"
        color="secondary"
        hidden={!submission.loading}
      />
      <Blur isVisible={submission.loading} />
      <div className={styles.dialogContent}>
        <Close onClick={onClose} className={styles.close} />
        <div>
          <Typography variant="h3" className={styles.header} color="primary">
            {strings.submissionDialog.title}
          </Typography>
        </div>
        <div>
          <TextField
            id="first-name"
            variant="outlined"
            label={strings.submissionDialog.firstName}
            {...withTextFieldProps("firstName")}
          />
          <TextField
            id="surname"
            variant="outlined"
            label={strings.submissionDialog.surname}
            {...withTextFieldProps("surname")}
          />
          <TextField
            id="email"
            variant="outlined"
            label={strings.submissionDialog.email}
            {...withTextFieldProps("email")}
          />
          <Autocomplete
            id="country"
            onChange={(e, value) => {
              formik.setFieldValue("country", value);
            }}
            options={countryOptions}
            renderInput={params => (
              <TextField
                label={strings.submissionDialog.country}
                variant="outlined"
                {...withTextFieldProps("country")}
                onChange={undefined as any}
                {...params}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password" // disable autocomplete and autofill
                }}
              />
            )}
          />
          <TextField
            id="company"
            variant="outlined"
            label={strings.submissionDialog.company}
            {...withTextFieldProps("company")}
          />
          <Select
            value={formik.values.industry}
            options={industries}
            labelText={strings.submissionDialog.dropdownLabel}
            labelId="industry-sector-label"
            fullWidth
            selectProps={{
              id: "industry-sector",
              onChange: (event: any) => {
                formik.setFieldValue("industry", event.target.value);
              }
            }}
          />
          <Button
            className={`${styles.checkBox}`}
            disableRipple
            onClick={() => {
              formik.setFieldValue(
                "termsAndConditions",
                !formik.values.termsAndConditions
              );
            }}
          >
            <Checkbox
              id="terms-and-conditions"
              value={formik.values.termsAndConditions}
              checked={formik.values.termsAndConditions}
            />
            <Typography className={styles.termsAndConditions}>
              {strings.submissionDialog.terms0}
            </Typography>
          </Button>
          <Typography
            className={`${styles.termsAndConditions} ${styles.conditionsMargin}`}
          >
            {strings.submissionDialog.terms1}
          </Typography>
          <Typography
            className={`${styles.termsAndConditions} ${styles.conditionsMargin}`}
          >
            {strings.submissionDialog.terms2}
          </Typography>
          <Typography
            className={`${styles.termsAndConditions} ${styles.conditionsMargin}`}
          >
            {strings.submissionDialog.terms3}
            <Link
              target="_blank"
              href={objectToProfiling.href}
              color="secondary"
            >
              {objectToProfiling.text}
            </Link>
            .
          </Typography>
          <Typography
            className={`${styles.termsAndConditions} ${styles.conditionsMargin}`}
          >
            {strings.submissionDialog.terms4}
            <Link target="_blank" href={privacyPolicy.href} color="secondary">
              {privacyPolicy.text}
            </Link>
            .
          </Typography>
          <CaptchaButton
            siteKey={siteKey}
            onCaptchaComplete={onSubmit}
            buttonProps={{
              id: "submit",
              className: styles.submitButton,
              variant: "contained",
              color: "secondary",
              disabled: !formik.isValid
            }}
          >
            {strings.submissionDialog.buttonText}
          </CaptchaButton>
        </div>
      </div>
    </Dialog>
  );
};
