import React from "react";

export interface SvgIconProps {
  width?: number;
  height?: number;
  viewBox?: string;
  className?: string;
  style?: React.CSSProperties;
  transform?: string;
  onClick?: () => void;
}

export const SvgIcon: React.FC<SvgIconProps> = ({
  width,
  height,
  viewBox,
  className,
  style,
  transform,
  children,
  onClick
}) => {
  return (
    <svg
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={viewBox}
      style={style}
      className={className}
      transform={transform}
    >
      {children}
    </svg>
  );
};

export default SvgIcon;
