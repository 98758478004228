import React from "react";
import PieChart from "react-minimal-pie-chart";

interface ResultPieChartProps {
  score: number;
  maxScore: number;
}

export const ResultPieChart: React.FC<ResultPieChartProps> = ({
  score,
  maxScore
}) => {
  return (
    <PieChart
      startAngle={90}
      style={{ width: 216, height: 216 }}
      data={[
        { title: "score", key: 1, value: score, color: "#FFA900" },
        {
          title: "max score",
          key: 2,
          value: maxScore - score,
          color: "#C6C6C6"
        }
      ]}
      label={({ x, y, dy, style, dataIndex }) => {
        /* We only want to show one label for both segments of the chart */
        if (dataIndex === 1) return <text />;
        return (
          <>
            <text
              key={1}
              textAnchor="middle"
              dominantBaseline="middle"
              fill="#eaaa00"
              x={x}
              y={y}
              dx={-18}
              dy={dy}
              style={style}
            >
              {score}
            </text>
            <text
              key={2}
              textAnchor="middle"
              dominantBaseline="middle"
              fill="#999999"
              x={x}
              y={y}
              dx={13}
              dy={dy}
              style={style}
            >
              / {maxScore}
            </text>
          </>
        );
      }}
      lineWidth={17}
      labelPosition={0}
      totalValue={maxScore}
      labelStyle={{
        fontSize: "20px",
        fontFamily: "Roboto"
      }}
    />
  );
};
