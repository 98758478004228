import {
  MOVE_FORWARD,
  MOVE_BACKWARD,
  MOVE_TO_QUESTION,
  RESET_SURVEY,
  ON_COMPLETE_SURVEY,
  MoveForwardAction,
  MoveBackwardAction,
  MoveToQuestionAction,
  ResetSurveyAction,
  OnCompleteSurveyAction,
  OnRadioAnswerClicked,
  ON_ANSWER_CLICKED,
  OnUndoCompleteSurveyAction,
  ON_UNDO_COMPLETE_SURVEY
} from "./types";
import { Option } from "../types";

export const moveForward = (): MoveForwardAction => ({
  type: MOVE_FORWARD
});

export const moveBackward = (): MoveBackwardAction => ({
  type: MOVE_BACKWARD
});

export const moveToQuestion = (number: number): MoveToQuestionAction => ({
  type: MOVE_TO_QUESTION,
  number
});

export const resetSurvey = (): ResetSurveyAction => ({
  type: RESET_SURVEY
});

export const onCompleteSurvey = (): OnCompleteSurveyAction => ({
  type: ON_COMPLETE_SURVEY
});

export const onUndoCompleteSurvey = (): OnUndoCompleteSurveyAction => ({
  type: ON_UNDO_COMPLETE_SURVEY
});

export const onRadioAnswerClicked = (
  page: number,
  question: number,
  answer: Option
): OnRadioAnswerClicked => ({
  type: ON_ANSWER_CLICKED,
  page,
  question,
  answer
});
