import { AppState } from "src/Store/RootReducer";
import { SurveyPage } from "src/Pages/Survey/SurveyPage";
import { RadioQuestionComponent } from "src/Pages/Survey/RadioQuestion";
import { Page } from "src/Components/Survey";
import { strings } from "./Strings";

const shuffle = () => 0.5 - Math.random();

const pages = Object.values(strings.survey).map(q => {
  const result: Page = {
    component: SurveyPage,
    questions: [
      {
        type: "RADIO_QUESTION",
        text: q.question,
        component: RadioQuestionComponent,
        options: [
          {
            name: q.option0,
            value: 0
          },
          {
            name: q.option1,
            value: 1
          },
          {
            name: q.option2,
            value: 2
          },
          {
            name: q.option3,
            value: 3
          }
        ].sort(shuffle)
      }
    ]
  };

  return result;
});

export const initialState: Partial<AppState> = {
  survey: {
    isFinished: false,
    currentPage: 0,
    pages: pages
  }
};
