import React from "react";
import { SvgIcon, SvgIconProps } from "./SvgIcon";

export const CheckboxUnchecked: React.FC<SvgIconProps> = ({
  width = 16,
  height = 16,
  viewBox = "0 0 16 16",
  ...props
}) => {
  return (
    <SvgIcon width={width} height={height} viewBox={viewBox} {...props}>
      <rect
        width="15"
        height="15"
        x="219.5"
        y="285.5"
        fill="none"
        fillRule="evenodd"
        stroke="#D4D4D4"
        rx="4"
        transform="translate(-219 -285)"
      />
    </SvgIcon>
  );
};

export default CheckboxUnchecked;
