import React from "react";
import { Grid, Typography, Theme } from "@material-ui/core";
import RadioOff from "src/Icons/RadioOff";
import RadioOn from "src/Icons/RadioOn";
import { Option } from "src/Components/Survey";
import { makeStyles, createStyles } from "@material-ui/styles";

interface RadioQuestionButtonProps {
  isCurrentAnswer: boolean;
  option: Option;
  onClick: (option: Option) => void;
}

const useStyles = makeStyles<
  Theme,
  { isCurrentAnswer: boolean; isHovered: boolean }
>(theme =>
  createStyles({
    root: {
      padding: 10
    },
    radio: {
      marginBottom: 40,
      width: 20
    },
    button: props => ({
      [theme.breakpoints.down("sm")]: {
        marginLeft: 30,
        marginRight: 30,
        width: "Calc(100% - 60px)"
      },
      minHeight: "100%",
      cursor: "pointer",
      width: "100%",
      borderStyle: "solid",
      borderWidth: 1,
      display: "flex",
      flexDirection: "row",
      padding: 15,
      borderColor: props.isHovered ? theme.palette.secondary.main : "#c6c6c6",
      backgroundColor:
        props.isCurrentAnswer || props.isHovered
          ? theme.palette.secondary.main
          : theme.palette.background.paper,
      color:
        props.isCurrentAnswer || props.isHovered
          ? theme.palette.background.paper
          : "#333333"
    }),
    text: {
      marginLeft: 16,
      textAlign: "left"
    }
  })
);

export const RadioQuestionButton: React.FC<RadioQuestionButtonProps> = ({
  isCurrentAnswer,
  option,
  onClick
}) => {
  const [isHovered, setIsHovered] = React.useState(false);

  const setHovered = React.useCallback(() => {
    setIsHovered(true);
  }, []);
  const resetHovered = React.useCallback(() => {
    setIsHovered(false);
  }, []);
  const styles = useStyles({ isCurrentAnswer, isHovered });

  const onSelection = React.useCallback(() => {
    onClick(option);
  }, [onClick, option]);

  return (
    <Grid item xs={12} md={6} classes={{ root: styles.root }}>
      <button
        onClick={onSelection}
        onMouseEnter={setHovered}
        onMouseLeave={resetHovered}
        className={styles.button}
        id={`option-${option.value}`}
      >
        <div className={styles.radio}>
          {isCurrentAnswer || isHovered ? <RadioOn /> : <RadioOff />}
        </div>
        <Typography className={styles.text}>{option.name}</Typography>
      </button>
    </Grid>
  );
};
