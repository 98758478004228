export class ApiClient {
  apiUrl: string;
  constructor() {
    const defaultUrl = `https://api.${window.location.host}/`;
    const overrideUrl = process.env.REACT_APP_API_URL;
    this.apiUrl = overrideUrl !== undefined ? overrideUrl : defaultUrl;

    if (!this.apiUrl.endsWith("/")) {
      this.apiUrl = this.apiUrl + "/";
    }
  }

  /* Regular HttpPost */
  post(url: string, payload: any) {
    const body = JSON.stringify(payload);

    const headers = {
      Accept:
        "application/json, application/xml, text/plain, text/html, multipart/form-data, *.*,",
      "Content-Type": "application/json"
    };

    return fetch(`${this.apiUrl}${url}`, {
      method: "POST",
      body: body,
      headers: headers
    });
  }
}
