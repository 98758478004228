import React, { Component } from "react";
import { BarHolder, Bars, Count } from "./ProgressBarStyle";

interface ProgressBarProps {
  currentPage: number;
  className?: string;
}

export class ProgressBar extends Component<ProgressBarProps> {
  colorOn: string;
  colorOff: string;
  questionNumber: number;

  constructor(props: ProgressBarProps) {
    super(props);
    this.colorOn = "#7ed321";
    this.colorOff = "rgba(240,240,240,0.2)";
    this.questionNumber = 8;
  }
  render() {
    let colorsOn = Array(this.props.currentPage + 1).fill(this.colorOn);
    let colorsOff = Array(
      this.questionNumber - this.props.currentPage - 1
    ).fill(this.colorOff);
    let colors = colorsOn.concat(colorsOff);
    return (
      <div>
        <BarHolder>
          {colors.map((color: string, index: number) => (
            <Bars color={color} key={index}></Bars>
          ))}
          <Count>
            {this.props.currentPage + 1 + "/" + this.questionNumber}
          </Count>
        </BarHolder>
      </div>
    );
  }
}
