import React from "react";
import { useSelector } from "react-redux";
import { SurveyState } from "./Redux/types";
import { RADIO_QUESTION } from "./Questions/RadioQuestion";
import { PageQuestionProps } from "./types";

interface SurveyProps {
  storeSelector: (state: any) => SurveyState;
}

export const Survey: React.FC<SurveyProps> = ({
  storeSelector = state => state.survey
}) => {
  const state = useSelector(storeSelector) as SurveyState;

  const page = state.pages[state.currentPage];

  const PageComponent = page.component;

  return (
    <PageComponent pageNumber={state.currentPage} isFinished={state.isFinished}>
      {page.questions.map((question, i) => {
        const pageQuestionProps: PageQuestionProps = {
          pageNumber: state.currentPage,
          questionNumber: i,
          totalPages: state.pages.length
        };

        switch (question.type) {
          case RADIO_QUESTION: {
            const Component = question.component;
            return (
              <Component
                question={question}
                pageProps={pageQuestionProps}
                key={question.text}
              />
            );
          }
        }

        throw new Error("Unrecognised question type");
      })}
    </PageComponent>
  );
};
