import * as Yup from "yup";
import { strings } from "./Strings";

export const submissionInitialState = {
  firstName: "",
  surname: "",
  email: "",
  company: "",
  industry: 0,
  termsAndConditions: false,
  country: ""
};

export const allowedCharacters = /^([^\W]|[ ()\-–@‘'`.,])*$/;

export const submissionValidation = Yup.object().shape({
  firstName: Yup.string()
    .required(strings.submissionDialog.firstNameRequired)
    .matches(allowedCharacters, "First name contains forbidden characters.")
    .max(100, strings.submissionDialog.maxLengthExceeded),
  surname: Yup.string()
    .required(strings.submissionDialog.surnameRequired)
    .matches(allowedCharacters, "Surname contains forbidden characters.")
    .max(100, strings.submissionDialog.maxLengthExceeded),
  email: Yup.string()
    .email(strings.submissionDialog.invalidEmail)
    .matches(allowedCharacters, "Email contains forbidden characters.")
    .required(strings.submissionDialog.emailRequired),
  company: Yup.string()
    .required(strings.submissionDialog.companyRequired)
    .matches(allowedCharacters, "Company contains forbidden characters.")
    .max(100, strings.submissionDialog.maxLengthExceeded),
  industry: Yup.number().moreThan(0, strings.submissionDialog.industryRequired),
  country: Yup.string()
    .typeError(strings.submissionDialog.countryRequired)
    .required(strings.submissionDialog.countryRequired),
  termsAndConditions: Yup.bool().oneOf([true])
});
