import { PageQuestionProps, Option } from "../types";

export const RADIO_QUESTION = "RADIO_QUESTION";

export interface RadioQuestion {
  type: typeof RADIO_QUESTION;
  text: string;
  options: Option[];
  component: React.ComponentType<RadioQuestionComponentProps>;
  currentAnswer?: RadioAnswer;
}

export interface RadioAnswer extends Option {}

export interface RadioQuestionComponentProps {
  question: RadioQuestion;
  pageProps: PageQuestionProps;
}
