import React from "react";
import { SvgIcon, SvgIconProps } from "./SvgIcon";

export const ArrowRight: React.FC<SvgIconProps> = ({
  width = 20,
  height = 38,
  viewBox = "0 0 20 38",
  ...props
}) => {
  return (
    <SvgIcon width={width} height={height} viewBox={viewBox} {...props}>
      <path
        fill="#FFF"
        fillOpacity=".5"
        fillRule="evenodd"
        d="M1.428 37.668a1.43 1.43 0 0 1-1.01-2.44L16.55 19.097.418 2.963A1.43 1.43 0 0 1 2.438.942l17.144 17.143a1.43 1.43 0 0 1 0 2.02L2.439 37.25c-.28.28-.646.419-1.011.419z"
      />
    </SvgIcon>
  );
};

export default ArrowRight;
