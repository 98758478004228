import React from "react";
import { SvgIcon, SvgIconProps } from "./SvgIcon";

export const RadioOn: React.FC<SvgIconProps> = ({
  width = 22,
  height = 22,
  viewBox = "0 0 22 22",
  ...props
}) => {
  return (
    <SvgIcon width={width} height={height} viewBox={viewBox} {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          stroke="#FFF"
          d="M1 11c0 5.6 4.4 10 10 10s10-4.4 10-10S16.6 1 11 1 1 5.4 1 11z"
        />
        <path
          fill="#FFF"
          d="M6 11c0 2.8 2.2 5 5 5s5-2.2 5-5-2.2-5-5-5-5 2.2-5 5z"
        />
      </g>
    </SvgIcon>
  );
};

export default RadioOn;
