import React from "react";
import { Typography, Grid } from "@material-ui/core";
import { strings } from "src/Constants/Strings";
import { useSelector } from "react-redux";
import { AppState } from "src/Store/RootReducer";
import { RadioAnswer } from "src/Components/Survey/Questions/RadioQuestion";
import { HorizontalLine } from "src/Components/Lines/HorizontalLine";
import { makeStyles, createStyles } from "@material-ui/styles";

const useStyles = makeStyles(() =>
  createStyles({
    answer: {
      padding: "10px 20px 0px 20px",
      display: "flex",
      flexDirection: "row"
    },
    answerSelected: {
      backgroundColor: "rgba(240, 240, 240, 0.5)",
      "-webkit-print-color-adjust": "exact"
    },
    answerText: {
      color: "#666666",
      lineHeight: 1.5,
      marginBottom: 20,
      flexGrow: 1
    },
    answerTextSelected: {
      fontWeight: 700
    },
    answerValue: {
      width: 20,
      marginLeft: 20,
      minWidth: 20
    },
    stringResult: {
      position: "relative",
      left: "12px"
    },
    questionSummary: {
      marginBottom: 10
    }
  })
);

export const Summary: React.FC = () => {
  const questions = useSelector((state: AppState) =>
    state.survey.pages.map(p => p.questions[0])
  );
  const styles = useStyles();
  return (
    <>
      <Grid item xs={8}>
        <Grid container spacing={3} justify="space-between">
          <Typography variant="h3" className={styles.stringResult}>
            {strings.results.summaryTitle}
          </Typography>
          <HorizontalLine style={{ marginBottom: 30 }} />
          {questions.map((q, i) => (
            <Grid item md={6} xs={12} key={i}>
              <Typography className={styles.questionSummary}>
                <b>
                  Q{i + 1}. {q.text}
                </b>
              </Typography>
              {q.options
                .sort((a, b) => a.value - b.value)
                .map(o => {
                  const isSelectedAnswer =
                    o.value === (q.currentAnswer as RadioAnswer).value;
                  return (
                    <div
                      className={`${styles.answer} ${
                        isSelectedAnswer ? styles.answerSelected : ""
                      }`}
                      key={o.value}
                    >
                      <Typography
                        className={`${styles.answerText} ${
                          isSelectedAnswer ? styles.answerTextSelected : ""
                        }`}
                      >
                        {o.name}
                      </Typography>
                      <Typography className={styles.answerValue}>
                        {o.value}
                      </Typography>
                    </div>
                  );
                })}
            </Grid>
          ))}
        </Grid>
      </Grid>
    </>
  );
};
