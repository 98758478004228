import React from "react";
import { makeStyles, createStyles } from "@material-ui/styles";

interface BlurProps {
  isVisible: boolean;
  backgroundColor?: string;
  zIndex?: number;
}

const useStyles = makeStyles(() =>
  createStyles({
    blur: {
      opacity: 0.5,
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%"
    }
  })
);

export const Blur: React.FC<BlurProps> = ({
  isVisible,
  backgroundColor = "#ffffff",
  zIndex = 10
}) => {
  const styles = useStyles();
  return (
    <>
      {isVisible && (
        <div
          className={styles.blur}
          style={{
            backgroundColor: backgroundColor,
            zIndex: zIndex
          }}
        />
      )}
    </>
  );
};
