import React from "react";
import {
  Grid,
  Typography,
  createStyles,
  makeStyles,
  Theme,
  Hidden,
  Link
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { AppState } from "src/Store/RootReducer";
import { strings } from "src/Constants/Strings";
import { HorizontalLine } from "src/Components/Lines/HorizontalLine";
import { RadioAnswer } from "src/Components/Survey/Questions/RadioQuestion";
import { ResultPieChart } from "./ResultPieChart";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    scoreTitle: {
      marginTop: 55,
      marginBottom: 30
    },
    resultText: {
      fontSize: 24,
      fontWeight: 300,
      marginBottom: 20
    },
    resultContact: {
      fontSize: 20,
      fontWeight: "bold"
    },
    resultRange: {
      fontSize: 24,
      fontWeight: "bold"
    }
  })
);

const chooseScoreText = (score: number): string[] => {
  if (score <= 8) return ["0-8", strings.results.score0];
  if (score <= 14) return ["9-14", strings.results.score1];
  if (score <= 19) return ["15-19", strings.results.score2];
  return ["20-24", strings.results.score3];
};

export const ResultsBox: React.FC = () => {
  const score = useSelector((state: AppState) =>
    state.survey.pages
      .map(p => (p.questions[0].currentAnswer as RadioAnswer).value)
      .reduce((a, b) => a + b, 0)
  );

  const maxScore = useSelector(
    (state: AppState) => state.survey.pages.length * 3
  );

  const styles = useStyles();
  return (
    <Grid item xs={8} md={8}>
      <Typography variant="h3" className={styles.scoreTitle}>
        {strings.results.scoreTitle}
      </Typography>
      <Grid container spacing={5}>
        <Grid item xs={12} md={4} lg={3}>
          <ResultPieChart score={score} maxScore={maxScore} />
        </Grid>
        <Hidden smDown>
          <Grid item md={1}>
            <HorizontalLine height="100%" width={1} />
          </Grid>
        </Hidden>
        <Grid item xs={12} md={7} lg={8}>
          <Typography className={styles.resultRange}>
            {chooseScoreText(score)[0]}
          </Typography>
          <Typography className={styles.resultText}>
            {chooseScoreText(score)[1]}
          </Typography>
          <Typography display="inline" className={styles.resultContact}>
            {strings.results.contactDetails}
          </Typography>
          <Link
            href={"mailto:" + strings.results.contactEmail}
            color="secondary"
            display="inline"
            className={styles.resultContact}
          >
            {strings.results.contactEmailText}
          </Link>
        </Grid>
      </Grid>
    </Grid>
  );
};
